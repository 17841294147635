<template>
  <div class="userMessageForm">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
      size='small'
    >
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="会员号">
        <el-input v-model="ruleForm.vipNumber" :disabled='true'></el-input>
      </el-form-item>
      <el-form-item label="生效时间">
        <el-input v-model="ruleForm.startTime" :disabled='true'></el-input>
      </el-form-item>
      <el-form-item label="有效期至">
        <el-input v-model="ruleForm.endTime" :disabled='true'></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱" prop="email">
        <el-input v-model="ruleForm.email"></el-input>
      </el-form-item>
      <el-form-item label="称谓" prop="sex">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio :label="0">先生</el-radio>
          <el-radio :label="1" >女士</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="出生日期" prop="birthday">
        <el-input v-model="ruleForm.birthday"></el-input>
      </el-form-item>
      <el-form-item label="收刊方式" prop="receiptWay">
        <el-radio-group v-model="ruleForm.receiptWay">
          <el-radio label="仅收取电子刊"></el-radio>
          <el-radio label="收取纸质刊"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="通讯地址" prop="address">
        <el-input v-model="ruleForm.address"></el-input>
      </el-form-item>
      <el-form-item label="邮政编码" prop="postCode">
        <el-input v-model="ruleForm.postCode"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobilePhone">
        <el-input v-model="ruleForm.mobilePhone"></el-input>
      </el-form-item>
      <el-form-item label="办公电话">
        <el-input v-model="ruleForm.telephone"></el-input>
      </el-form-item>
      <el-form-item label="个人主页">
        <el-input v-model="ruleForm.person"></el-input>
      </el-form-item>
      <el-form-item label="最高学历" prop="highestEducation">
        <el-radio-group v-model="ruleForm.HighestEducation">
          <el-radio label="博士"></el-radio>
          <el-radio label="硕士"></el-radio>
          <el-radio label="本科"></el-radio>
          <el-radio label="专科"></el-radio>
          <el-radio label="博士后"></el-radio>
          <el-radio label="其它"></el-radio>
          <el-radio label="初中"></el-radio>
          <el-radio label="高中"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="获得时间" prop="getTime">
        <el-input v-model="ruleForm.getTime"></el-input>
      </el-form-item>
      <el-form-item label="任职单位" prop="workPlace">
        <el-input v-model="ruleForm.workPlace"></el-input>
      </el-form-item>
      <el-form-item label="现任职务" prop="duty">
        <el-input v-model="ruleForm.duty"></el-input>
      </el-form-item>
      <el-form-item label="工作年限" prop="workLength">
        <el-input v-model="ruleForm.workLength"></el-input>
      </el-form-item>
      <el-form-item label="职称类别" prop="professionalTitle">
        <el-radio-group v-model="ruleForm.professionalTitle">
          <el-radio label="正高级"></el-radio>
          <el-radio label="副高级"></el-radio>
          <el-radio label="中级"></el-radio>
          <el-radio label="初级"></el-radio>
          <el-radio label="其它"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="任职机构类型"  prop="organizationType">
        <el-radio-group v-model="ruleForm.organizationType">
          <el-radio label="科研机构"></el-radio>
          <el-radio label="教育机构"></el-radio>
          <el-radio label="企业"></el-radio>
          <el-radio label="政府"></el-radio>
          <el-radio label="社会团体"></el-radio>
          <el-radio label="事业单位"></el-radio>
          <el-radio label="其它"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="入会渠道" prop="channel">
        <el-select v-model="ruleForm.participateChannel" placeholder="请选择活动区域">
          <el-option label="渠道一" value="shanghai"></el-option>
          <el-option label="渠道二" value="beijing"></el-option>
          <el-option label="渠道三" value="beijing"></el-option>
          <el-option label="渠道四" value="beijing"></el-option>
          <el-option label="渠道五" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="ruleForm.note"></el-input>
      </el-form-item>
      <el-form-item class='submitBtn'>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置输入</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "memberDetail",
  props: {
    ruleForm:Object
    },
  data() {
    return {
      // 个人信息检验规则
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" },
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        sex: [
          { required: true, message: "请选择称谓", trigger: "change" },
        ],
        birthday: [
          { required: true, message: "请输入生日", trigger: "blur" },
        ],
        receiptWay: [
          { required: true, message: "请选择收刊方式", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入通信地址", trigger: "blur" },
        ],
        postCode: [
          { required: true, message: "请输入邮政编码", trigger: "blur" },
        ],
        mobilePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
        ],
        HighestEducation: [
          { required: true, message: "请选择最高学历", trigger: "change" },
        ],
        getTime: [
          { required: true, message: "请输入获得最高学历时间", trigger: "blur" },
        ],
        workPlace: [
          { required: true, message: "请输入任职单位", trigger: "blur" },
        ],
        duty: [
          { required: true, message: "请输入现任职务", trigger: "blur" },
        ],
        workLength: [
          { required: true, message: "请输入工作年限", trigger: "blur" },
        ],
        professionalTitle: [
          { required: true, message: "请选择职称类别", trigger: "change" },
        ],
        organizationType: [
          { required: true, message: "请选择任职机构类型", trigger: "change" },
        ],
        intention: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个希望参加的活动",
            trigger: "change",
          },
        ]
      },
    };
  },
  methods: {
    // 点击保存修改
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          alert("保存成功!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置修改
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.userMessageForm{
  width: 850px;
  padding: 50px 50px 50px 0;
  border: 1px solid rgba(17, 34, 51, 0.1);
  margin-left: 50%;
  transform: translate(-50%);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.submitBtn{
  margin-left: 50%;
  transform: translate(-50%);
}
</style>